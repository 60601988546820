<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>
            <img src="/img/Alerts/plusbig_icon.png"> City
          </h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addCity" autocomplete="off" data-vv-scope="add-city">
            <h3>Select Country</h3>
            <select
              v-model="countryIso"
              name="country"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-city.country') }"
              @change="getStatesByCountry()"
            >
              <option value="0" selected>Select Country</option>
              <option v-for="country in countries" :key="country.id" :value="country.iso_code">{{country.name}}</option>
            </select>
            <h3>Select State</h3>
            <select
              v-model="city.stateId"
              name="state"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-city.state') }"
            >
              <option value="" selected>Select State</option>
              <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
            </select>
            <h3>Google Geo City</h3>
            <vue-google-autocomplete
              ref="address"
              autocomplete="off"
              :id="'map-edit-' + editCityFlag"
              placeholder="City"
              v-on:placechanged="getAddressData"
              :country="[countryIso]"
              v-model="city.address"
              types="(cities)"
              name="address"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-city.address') }"
              @change="clear"
            ></vue-google-autocomplete>
            <!-- <span class="error" v-show="errors.has('address')">{{ errors.first('address') }}</span> -->
            <h3>Filter Search Address</h3>
            <input
              type="text"
              name="city"
              placeholder="City"
              v-model="city.city"
              :class="{'input': true, 'is-danger': errors.has('add-city.city') }"
              v-validate="'required'"
            >
            <div class="row">
              <div class="col-xs-12" :class="{ 'col-sm-12': editCityFlag }">
                <button
                  type="submit"
                  class="btn-lg-green"
                  :disabled="submittingForm"
                >{{ editCityFlag ? 'UPDATE' : 'ADD' }} CITY</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  name: 'StorePopup',
  props: ['popupId', 'editCityFlag', 'locationDetails', 'stores', 'closeStoreEditPopup', 'city'],
  data () {
    return {
      states: [],
      countries: [],
      countryIso: 'US',
      countryId: '',
      submittingForm: false

    }
  },
  components: {
    VueGoogleAutocomplete
  },
  mounted () {
    this.getCountries()
  },

  methods: {
    getStatesByCountry () {
      this.countries.forEach((x) => {
        if (this.countryIso === x.iso_code) {
          this.countryId = x.id
        }
      })
      if (this.countryId !== '') {
        this.$http.get('states?country_id=' + this.countryId).then((response) => {
          this.states = response.body.data
        })
      } else {
        this.states = []
      }
    },
    getCountries () {
      this.$http
        .get('countries?service_enabled=true')
        .then(
          (response) => {
            this.countries = response.body.data
            this.getStatesByCountry()
          },
          (response) => {
          }
        )
    },
    addCity () {
      this.$validator.validateAll('add-city').then((result) => {
        if (result) {
          this.submittingForm = true
          if (this.editCityFlag) {
            this.$http
              .put(process.env.VUE_APP_API_BASE_URL + '/cities/' + this.city.id, this.city, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$notify({ type: 'success', text: 'City has been updated successfully!' })
                  this.$refs.popupCloseButton.click()
                  this.$emit('refreshStoreLocationsList')
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          } else {
            this.$http
              .post(process.env.VUE_APP_API_BASE_URL + '/cities', this.city, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$notify({ type: 'success', text: 'City has been added successfully!' })
                  this.$emit('refreshStoreLocationsList')
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          }
        } else {
          if (this.errors.has('add-city.address')) {
            this.$notify({ type: 'error', text: 'Please select the correct address from the list' })
          }
        }
      })
    },

    getStoreLocation () {
      this.$emit('getLocationDetails', this.location)
    },
    resetForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.address.clear()
      this.$nextTick()
        .then(() => {
          this.getCountries()
          this.errors.clear('add-city')
        })
    },
    getAddressData (addressData, placeResultData, id) {
      this.address = placeResultData.formatted_address
      this.city.address = placeResultData.formatted_address
      this.city.city = addressData.locality
      // for (let i = placeResultData.address_components.length - 1; i > 0; i--) {
      //   if (placeResultData.address_components[i].types[0] === 'administrative_area_level_1') {
      //     this.city.state = placeResultData.address_components[i].long_name
      //   }
      // }
      this.city.latitude = addressData.latitude
      this.city.longitude = addressData.longitude
    },
    clear () {
      if (this.address !== this.city.address) this.city.address = ''
    }
  },
  watch: {
    locationDetails () {
      this.city = Object.assign({}, this.locationDetails)
      this.$refs.address.update(this.city.address)
    },
    closeStoreEditPopup () {
      if (this.closeStoreEditPopup) {
        this.$refs.popupCloseButton.click()
      }
    }
  }
}
</script>
<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
</style>
