var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box outer-box" }, [
    _c(
      "div",
      { staticClass: "inner-box" },
      [
        _c("span", { staticClass: "title", attrs: { title: _vm.city.city } }, [
          _c("strong", [
            _vm._v(_vm._s(_vm.city.state.name) + " (Launched State)")
          ]),
          _c("br")
        ]),
        _c("br"),
        _c(
          "span",
          { staticClass: "description", attrs: { title: _vm.city.address } },
          [_vm._v(_vm._s(_vm.city.address) + " (Added Geo-City)")]
        ),
        _c(
          "router-link",
          {
            attrs: {
              target: "_blank",
              to:
                "/sadmin/picklrs?lat=" +
                _vm.city.latitude +
                "&lng=" +
                _vm.city.longitude
            }
          },
          [_vm._v("View Nearby Picklrs")]
        ),
        _c("br"),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn-negative pull-left",
              staticStyle: { "margin-top": "0px" },
              attrs: {
                "data-target": "#delete-city-popup",
                "data-toggle": "modal",
                type: "button"
              },
              on: {
                click: function($event) {
                  return _vm.openEditCityPopup(_vm.city.id)
                }
              }
            },
            [_vm._v("\n            DELETE\n          ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-positive pull-right",
              staticStyle: { "margin-top": "0px" },
              attrs: {
                "data-target": "#edit-city-popup",
                "data-toggle": "modal",
                type: "button"
              },
              on: {
                click: function($event) {
                  return _vm.openEditCityPopup(_vm.city.id)
                }
              }
            },
            [_vm._v("\n            EDIT\n          ")]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }