<template>
  <div class="green-border-box in-padd white-bg">
     <div class="row">
      <div class="col-xs-12 col-md-12">
        <input
          class="search-box"
          type="search"
          q="store"
          v-model="query"
          placeholder="Search City"
        >
      </div>
    </div>
    <div class="row" v-if="cities.length> 0">
      <div
        class="col-xs-12 col-sm-6 item-list"
        v-for="city in cities"
        :key="city.id"
      >
        <CityItem :city="city" @getCityDetails="getCityDetails"/>
      </div>
    </div>
    <h2
      v-else
      style="text-align:center;"
    >No City Found in system!</h2>
    <h2 v-if="loadingCities" style="text-align:center;">Loading Cities...</h2>
    <div
      class="text-center"
      v-if="hasMoreCities && !loadingCities"
      @click="getCities"
    >
      <button class="btn-positive">Load more</button>
    </div>
    <CityPopup
      @refreshStoreLocationsList="reFilter"
      :editCityFlag="false"
      :city="cityDetails"
      popupId="add-city-popup"
    />
    <CityPopup
      @refreshStoreLocationsList="reFilter"
      :editCityFlag="true"
      :city="cityDetails"
      popupId="edit-city-popup"
    />
    <DeleteCityPopup
      @cityDeleted="cityDeleted"
      :city="cityDetails"
      popupId="delete-city-popup"
    />
  </div>
</template>

<script>
import CityPopup from '@/components/SAdmin/CityPopup'
import CityItem from '@/components/SAdmin/CityItem'
import DeleteCityPopup from '@/components/SAdmin/DeleteCityPopup'

export default {
  name: 'Stores',
  data () {
    return {
      timer: 0,
      hasMoreCities: false,
      loadingCities: true,
      editCityFlag: false,
      cities: [],
      cityDetails: {
        city: '',
        address: '',
        stateId: '',
        longitude: '',
        latitude: ''
      },
      query: '',
      page: 0,
      size: 100
    }
  },
  components: {
    CityPopup,
    CityItem,
    DeleteCityPopup
  },
  mounted () {
    this.getCities()
  },
  methods: {
    getCities () {
      this.loadingCities = true
      let q = { page: this.page, size: this.size, query: this.query }
      this.$http.get(process.env.VUE_APP_API_BASE_URL + '/cities', { params: q }).then(function (response) {
        if (this.page === q.page && this.size === q.size) {
          this.cities.push(...response.body.cities)
          this.loadingCities = false
        }
      })
    },

    cityDeleted () {
      this.reFilter()
    },
    reFilter () {
      this.loadingCities = true
      this.cities = []
      this.page = 0
      this.getCities()
    },
    getCityDetails (cityId) {
      let cityDetails = this.cities.filter(obj => {
        return obj.id === cityId
      })
      // this.editCityFlag = true
      this.cityDetails = cityDetails[0]
      console.log(this.cityDetails)
    }
  },

  watch: {
    query () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  text-align: right;
  padding: 6px 15px;
  margin-bottom: 20px;
}

.sort-control .btn-select {
  margin-bottom: 7px;
}

.filters {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}

@media only screen and (max-width: 991px) {
  .sort-control {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .filter-control {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-control {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px;
    display: inline-block;
  }

  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
